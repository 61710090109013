import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarData } from './CardData';
import CardItem from './CardItem';
import CardItem2 from './CardItem2';
import styled from 'styled-components';
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../firebase.config";
import {ClipLoader} from "react-spinners";

const Container = styled.div`
  max-width: 100vw;
  background: rgb(220, 220, 220);
  padding: 40px;
  padding-top: 75px;
  z-index: 10;

  @media screen and (max-width: 768px) {
    padding: 10px;
    padding-top: 25px;
    padding-bottom: 50px;
  }
  height: 100%;

  /* @media screen and (max-height: 660px) {
    height: 110vh;
  }
  @media screen and (max-height: 600px) {
    height: 120vh;
  }
  @media screen and (max-height: 550px) {
    height: 140vh;
  }
  @media screen and (max-height: 500px) {
    height: 160vh;
  }
  @media screen and (max-width: 500px) {
    height: 90vh;
  }
  @media screen and (max-width: 380px) {
    height: 110vh;
  } */
`;
const Wrapper = styled.div``;
const Header = styled.div`
  padding-bottom: 32px;
  margin-top: -24px;

  font-size: 2rem;
  font-weight: 300;
  margin-left: 20px;
  @media screen and (max-width: 768px) {
    padding-top: 40px;
    margin-top: 0;
  }
`;
const CardWrapper = styled.div`
  display: grid;
  grid-gap: 30px;
`;

const MobileLatest = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [carData, setCarData] = useState([]);
  const carsCollection = collection(db, 'cars');

  useEffect(() => {
    async function getCarData() {
      const carWithImages = await buildCarData();
      setCarData(carWithImages);
    }

    void getCarData();
  })
  
  const fetchCarDetails = async () => {
    try {
      const carDocs = await getDocs(carsCollection);
      return carDocs.docs.map(car => ({
        id: car.id,
        ...car.data()
      }));
    } catch (err) {
      console.log("Error getting car document", err);
    }
  }

  const buildCarData = async () => {
    const carWithImages = [];
    await fetchCarDetails().then(async (results) => {
      for(const car of results) {
        const imagesRef = collection(db, `cars/${car.id}/images`);
        const imageDocs = await getDocs(imagesRef);
        const images = imageDocs.docs.map(doc => ({
          docId: doc.id,
          ...doc.data()
        }));

        const sortedImages = images.slice().sort((a, b) => a.id - b.id);
        carWithImages.push({ ...car, images: sortedImages });
      }
    });
    return carWithImages;
  }
  
  return (
    <Container>
      <Wrapper>
        <Header>Latest Arrivals</Header>
        
          {carData.length === 0 ? <div className={"w-full h-[60vh] sm:h-[40vh] flex items-center justify-center"}><ClipLoader size={30} color={'#000'} loading={true} /></div> :
              <Slider {...settings}>
                {carData
              .slice()
              .sort((a, b) => a.order - b.order)
              .slice(0, 4)
              .map((item) => (
            <CardWrapper>
              <CardItem
                src={item.images[0].url}
                text={item.title}
                text2={item.spec}
                label={item.label}
                path={item.sold ? '/sold' : `/latest-stock/${item.id}`}
                price={item.price.toLocaleString()}
                miles={item.miles.toLocaleString()}
              />
            </CardWrapper>
              ))}
            </Slider>
          }
      </Wrapper>
    </Container>
  );
};

export default MobileLatest;
