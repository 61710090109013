import {useCallback, useEffect, useState} from "react";
import {addDoc, collection} from "firebase/firestore";
import {db, storage} from "../../../firebase.config";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import './portal.css';
import {useDropzone} from "react-dropzone";
import {Button} from "../../Button/ButtonElements";
import {useNavigate} from "react-router-dom";
import FinaliseDetails from "./FinaliseDetails";

function AddACar() {
    const [carTitle, setCarTitle] = useState("");
    const [carSpec, setCarSpec] = useState("");
    const [price, setPrice] = useState(0);
    const [miles, setMiles] = useState(0);
    const [para1, setPara1] = useState("");
    const [para2, setPara2] = useState("");
    const [para3, setPara3] = useState("");
    const [para4, setPara4] = useState("");
    const [imageUrls, setImageUrls] = useState([]);
    const [carRef, setCarRef] = useState("");

    const onDrop = useCallback(async (files) => {
        for (const file of files) {
            try {
                const imageFolderRef = ref(storage, `${carTitle}/${file.name}`);
                await uploadBytes(imageFolderRef, file);
                const downloadUrl = await getDownloadURL(imageFolderRef);
                setImageUrls((prevFiles) => [...prevFiles, downloadUrl]);
            } catch (err) {
                console.log("Error uploading images:", err);
            }
        }
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    const navigate = useNavigate();

    const carsRef = collection(db, 'cars');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(carsRef, {
                title: carTitle,
                spec: carSpec,
                price: price,
                miles: miles,
                para1: para1,
                para2: para2,
                para3: para3,
                para4: para4,
                label: 'JUST IN'
            });
            const imagesRef = collection(db, `cars/${docRef.id}/images`);
            await Promise.all(imageUrls.map(async (url, index) => {
                await addDoc(imagesRef, {
                    url: url,
                    id: index + 1
                })
            }));
            setCarTitle("");
            setCarSpec("");
            setPrice(0);
            setMiles(0);
            setPara1("");
            setPara2("");
            setPara3("");
            setPara4("");
            setImageUrls([]);
            localStorage.setItem('docId', docRef.id);
        } catch (err) {
            console.log("error adding car:", err);
        }
    }
    useEffect(() => {
        const docId = localStorage.getItem('docId');
        if (docId !== "")
            setCarRef(docId)
    }, [handleSubmit]);
    
    return (
        carRef ? <FinaliseDetails carRef={carRef}/> :
            <div className={"w-full flex flex-col items-center justify-center py-8 pb-32"}>
                <div className={"border border-[#eee] rounded-lg p-6 shadow-xl lg:w-[40%] md-[60%] sm:w-[80%] w-[90%]"}>
                    <form className={"flex flex-col gap-[15px]"}>
                        <div>
                            <label className={"flex flex-col font-medium gap-[5px]"}>Car Title
                                <input
                                    className={"details-input"}
                                    type={"text"}
                                    placeholder={"Car Title"}
                                    value={carTitle}
                                    onChange={(e) => setCarTitle(e.target.value)}
                                />
                            </label>
                        </div>
                        <div>
                            <label className={"font-medium flex flex-col gap-[5px]"}>Car Specification
                                <input
                                    className={"details-input"}
                                    type={"text"}
                                    placeholder={"Car Spec"}
                                    value={carSpec}
                                    onChange={(e) => setCarSpec(e.target.value)}
                                />
                            </label>
                        </div>
                        <div>
                            <label className={"ffont-medium flex flex-col gap-[5px]"}>Car Price
                                <input
                                    className={"details-input"}
                                    type={"text"}
                                    placeholder={"Price"}
                                    value={price}
                                    onChange={(e) => setPrice(Number(e.target.value))}
                                />
                            </label>
                        </div>
                        <div>
                            <label className={"font-medium flex flex-col gap-[5px]"}>Mileage
                                <input
                                    className={"details-input"}
                                    type={"text"}
                                    placeholder={"Miles"}
                                    value={miles}
                                    onChange={(e) => setMiles(Number(e.target.value))}
                                />
                            </label>
                        </div>
                        <div>
                            <label className={"font-medium flex flex-col gap-[5px]"}>Car Description
                                <textarea
                                    className={"details-text-area"}
                                    placeholder={"Paragraph one"}
                                    value={para1}
                                    onChange={(e) => setPara1(e.target.value)}
                                />
                                <textarea
                                    className={"details-text-area"}
                                    placeholder={"Paragraph two"}
                                    value={para2}
                                    onChange={(e) => setPara2(e.target.value)}
                                />
                                <textarea
                                    className={"details-text-area"}
                                    placeholder={"Paragraph three"}
                                    value={para3}
                                    onChange={(e) => setPara3(e.target.value)}
                                />
                                <textarea
                                    className={"details-text-area"}
                                    placeholder={"Paragraph four"}
                                    value={para4}
                                    onChange={(e) => setPara4(e.target.value)}
                                />
                            </label>
                        </div>
                        <label className={"font-medium flex flex-col gap-[5px]"}>Photos
                            <div {...getRootProps()}
                                 className={"w-full h-[250px] bg-gray-100 rounded-md my-4 hover:cursor-pointer hover:bg-gray-300 transition duration-300 ease-in-out flex items-center justify-center"}>
                                <input {...getInputProps()} />
                                <div className={"flex flex-col gap-[10px] items-center"}>
                                    {
                                        isDragActive ?
                                            <p className={"text-center w-[80%]"}>Drop the photos here ...</p> :
                                            <p className={"text-center w-[80%]"}>Drag 'n' drop the photos here, or click
                                                to
                                                select files</p>
                                    }
                                    <i className="fa-solid fa-arrow-up-from-bracket"></i>
                                </div>
                            </div>
                            <div className={"flex flex-col gap-[10px]"}>
                                <p className={"font-light"}>Wait for all photos to uplaod</p>
                            <div className={"flex gap-[10px] items-center"}>
                                <div className={"text-[1.2rem]"}>{imageUrls.length}</div>
                                <i className={"fa-solid fa-file-image text-[rgb(178,12,78)] text-[1.2rem]"}></i>
                            </div>
                            </div>
                        </label>
                        <div className={"w-full flex justify-center"}>
                            <Button onClick={handleSubmit} className={"w-auto"}>Add car</Button>
                        </div>
                    </form>
                </div>
            </div>
    )
}

export default AddACar;