import PortalNav from "../portal-nav";
import OrderCars from "./OrderCars";

export default function OrderCarsPage() {
    return (
        <div>
            <PortalNav />
            <OrderCars />
        </div>
    )
}