import {useEffect, useState} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebase.config";
import LoginPage from "../login-page/login-page";
import PortalPage from "../portal-page/portal-page";

function FirebaseOutlet() {
    const [signedIn, setSignedIn] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setSignedIn(true);
            } else {
                setSignedIn(false);
            }
        })
        return () => unsubscribe();
    }, []);
    
    return (
        <div>
            {!signedIn ? <LoginPage /> : <PortalPage />}
        </div>
    )
}

export default FirebaseOutlet;