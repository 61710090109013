import PortalNav from "../../portal-nav";
import UpdateCar from "./UpdateCar";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {collection, doc, getDoc, getDocs} from "firebase/firestore";
import {db} from "../../../../../firebase.config";

export default function UpdateCarPage() {
    const { carId } = useParams();
    const [car, setCar] = useState(null);

    async function fetchCar() {
        const docRef = doc(db, 'cars', carId);
        const carDoc = await getDoc(docRef);
        return carDoc.data();
    }
    
    async function addImagesToCar() {
        await fetchCar().then(async (car) => {
            const imagesRef = collection(db, `cars/${carId}/images`);
            const imageDocs = await getDocs(imagesRef);
            const images = imageDocs.docs.map(doc => ({
                docId: doc.id,
                ...doc.data()
            }));
            
            const sortedImages = images.slice().sort((a, b) => a.id - b.id);
            setCar({...car, images: sortedImages});
        })
    }
    
    useEffect(() => {
        void addImagesToCar();
    }, []);
    return (
        <div>
            <PortalNav />
            {car ? <UpdateCar car={car} /> : <div>Loading...</div>}
        </div>
    )
}