import {useState} from "react";
import {signInWithEmailAndPassword} from 'firebase/auth';
import {auth} from "../../../firebase.config";
import "./login.css";
import {Button} from "../../Button/ButtonElements";

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const validateInput = () => {
        const emailRegex = /^[^\s@<>]+@[^\s@<>]+\.[^\s@<>]+$/;
        const passwordRegex = /^[^\s<>]{6,}$/;

        if (!emailRegex.test(email)) {
            setError('Invalid email address or password');
            return false;
        }

        if (!passwordRegex.test(password)) {
            setError('Invalid password or password');
            return false;
        }

        return true;
    };
    const onLogin = (e) => { 
        e.preventDefault();
        if(validateInput()) {
            signInWithEmailAndPassword(auth, email, password).then(() => {
            })
                .catch(() => {
                }) 
        }
    }
    return (
        <div className={"w-full h-[90vh] flex flex-col gap-[50px] items-center justify-center "}>
            <img src={"/Logo.PNG"} alt={""} className={"w-[175px]"} />
            <div className={"border border-[#eee] rounded-lg shadow-lg w-[90%] sm:w-[500px] px-4 md:px-6 py-6 md:py-8 flex flex-col gap-[30px]"}>
                <div>
                    <div className={"font-semibold text-[1.3rem]"}>Please Sign In</div>
                    <p className={"text-[#333] text-[0.8rem]"}>COYR</p>
                </div>
                <form className={"flex flex-col gap-[15px]"}>
                    <div className={"flex flex-col"}>
                        <label htmlFor={"email-address"}>Email address</label>
                        <input
                            className={"input"}
                            id={"email-address"}
                            name={"email"}
                            type={"email"}
                            required
                            placeholder={"Email address"}
                            onChange={e => setEmail(e.target.value)}/>
                    </div>
                    <div className={"flex flex-col"}>
                        <label htmlFor="password">
                            Password
                        </label>
                        <input
                            className={"input"}
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Password"
                        />
                    </div>
                    {error && <div className={"text-red-600"}>{error}</div> }
                    <div className={"w-full flex mt-[15px]"}>
                    <Button
                        type="submit"
                        onClick={onLogin}
                        className={"w-[40%]"}
                    >
                        Sign in
                    </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginPage;