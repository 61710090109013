import {Button} from "../../../../Button/ButtonElements";
import {useEffect, useState} from "react";
import {db} from "../../../../../firebase.config";
import {useNavigate, useParams} from "react-router-dom";
import {addDoc, collection, doc, updateDoc, deleteDoc} from "firebase/firestore";
import {
    closestCorners,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {ImagesColumn} from "../../components/ImagesColumn";
import {arrayMove, sortableKeyboardCoordinates} from "@dnd-kit/sortable";

export default function UpdateCar(car) {
    const [carTitle, setCarTitle] = useState(car.car.title ? car.car.title : "");
    const [carSpec, setCarSpec] = useState(car.car.spec ? car.car.spec : "");
    const [price, setPrice] = useState(car.car.price ? car.car.price : 0);
    const [miles, setMiles] = useState(car.car.miles ? car.car.miles : 0);
    const [para1, setPara1] = useState(car.car.para1 ? car.car.para1 : "");
    const [para2, setPara2] = useState(car.car.para2 ? car.car.para2 : "");
    const [para3, setPara3] = useState(car.car.para3 ? car.car.para3 : "");
    const [para4, setPara4] = useState(car.car.para4 ? car.car.para4 : "");
    const [sold, setSold] = useState(car.car.sold ? car.car.sold : false);
    const [images, setImages] = useState([]);
    const [label, setLabel] = useState(car.car.label ? car.car.label : "");

    const { carId } = useParams();

    const navigate = useNavigate();

    const carDocRef = doc(db, 'cars', carId);

    useEffect(() => {
        setImages(car.car.images);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const docRef = await updateDoc(carDocRef, {
                title: carTitle,
                spec: carSpec,
                price: price,
                miles: miles,
                label: label,
                para1: para1,
                para2: para2,
                para3: para3,
                para4: para4,
            });
        } catch (err) {
            console.log("error adding car:", err);
        }
    }

    const getImagePos = id => images.findIndex(image =>
        image.id === id
    )

    const handleDragEnd = event => {
        const {active, over} = event;

        if(active.id === over.id) return;

        setImages(images => {
            const originalPos = getImagePos(active.id)
            const newPos = getImagePos(over.id)

            return arrayMove(images, originalPos, newPos)
        });
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const reOrderImages = async () => {
        try {
            console.log("Called");
            await images.forEach((image, index) => {
                image.id = index + 1
            });
            for (const image of images) {
                const collectionRef = doc(db, `cars/${car.id}/images/${image.docId}`);
                await updateDoc(collectionRef, {
                    id: image.id,
                    url: image.url
                })
                    .then(() => {
                        localStorage.removeItem('docId');
                        navigate("/portal");
                    })
                    .catch(error => {
                        console.log(error.message)
                    })
            }
        } catch (err) {
            console.log('Error updating order:', err);
        }
    }

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(db, 'cars', carId));
            navigate('/portal/update');
        } catch (error) {
            console.error("Error removing document: ", error);
        }
    };
    
    const markAsSold = async () => {
        try {
            await updateDoc(carDocRef, {
                label: 'SOLD',
                sold: true,
            });
            setLabel('SOLD');
            setSold(true);
        } catch(error) {
            console.log("Error marking as sold:", error);
        }
    }

    const putOnMarket = async () => {
        try {
            await updateDoc(carDocRef, {
                label: 'JUST IN',
                sold: false,
            });
            setLabel('JUST IN');
            setSold(false);
        } catch(error) {
            console.log("Error marking as sold:", error);
        }
    }
    
    function goToReOrder() {
        localStorage.setItem('docId', carId);
        navigate("/portal");
    }
    
    return (
        <div className={"bg-gray-100 w-full flex items-center justify-center flex-col"}>
            <div className={"text flex flex-col items-center py-12"}>
                <div className={"flex flex-col sm:flex-row gap-[20px] lg:gap-[100px]"}>
                    {!sold ?
                        <div onClick={markAsSold} className={"flex items-center justify-center w-[300px] h-[60px] bg-green-600 py-4 px-6 rounded-md text-white transition duration-300 hover:bg-green-700 hover:cursor-pointer"}>
                            <i
                                className="fa-solid fa-check-double pr-2"></i> Mark as sold
                        </div>
                        :
                        <div onClick={putOnMarket} className={"flex items-center justify-center w-[300px] h-[60px] bg-green-600 py-4 px-6 rounded-md text-white transition duration-300 hover:bg-green-700 hover:cursor-pointer"}>
                            <i
                                className="fa-solid fa-check-double pr-2"></i> Put back on the market
                        </div>
                    }
                    <div className={"flex flex-col items-center w-full"}>
                    <div className={"py-2 text-[1.8rem] font-medium text-center"}>Update your {car.car.title}!</div>
                    <div className={"border-b border-b-[#333] w-[100px] h-1"}></div>
                </div>
                    <div onClick={handleDelete} className={"flex items-center justify-center w-[300px] h-[60px] bg-red-600 py-4 px-6 rounded-md text-white transition duration-300 hover:bg-red-700 hover:cursor-pointer"}>
                        <i
                            className="fa-solid fa-trash-can pr-2"></i> Delete
                        car
                    </div>
                </div>
            </div>
            {car ? (
                <div className={"w-full flex-col sm:w-[90%] flex sm:flex-row justify-center gap-[50px]"}>
                    <div>
                        <div className={"pb-4"}>Edit car details</div>
                        <div className={"w-full md:w-[600px] border border-[#eee] rounded-md px-6 py-4 bg-white"}>
                 <form className={"flex flex-col gap-[15px]"}><div>
                     <label className={"flex flex-col font-medium gap-[5px]"}>Car Title
                         <input
                             className={"details-input"}
                             type={"text"}
                             placeholder={"Car Title"}
                             value={carTitle}
                             onChange={(e) => setCarTitle(e.target.value)}
                         />
                     </label>
                 </div><div>
                     <label className={"font-medium flex flex-col gap-[5px]"}>Car Specification
                         <input
                             className={"details-input"}
                             type={"text"}
                             placeholder={"Car Spec"}
                             value={carSpec}
                             onChange={(e) => setCarSpec(e.target.value)}
                         />
                     </label>
                 </div><div>
                     <label className={"font-medium flex flex-col gap-[5px]"}>Car Price
                         <input
                             className={"details-input"}
                             type={"text"}
                             placeholder={"Price"}
                             value={price}
                             onChange={(e) => setPrice(Number(e.target.value))}
                         />
                     </label>
                 </div><div>
                     <label className={"font-medium flex flex-col gap-[5px]"}>Mileage
                         <input
                             className={"details-input"}
                             type={"text"}
                             placeholder={"Miles"}
                             value={miles}
                             onChange={(e) => setMiles(Number(e.target.value))}
                         />
                     </label>
                 </div>
                     <div>
                         <label className={"font-medium flex flex-col gap-[5px]"}>Label
                             <input
                                 className={"details-input"}
                                 type={"text"}
                                 placeholder={"Label"}
                                 value={label}
                                 onChange={(e) => setLabel(e.target.value)}
                             />
                         </label>
                     </div>
                     <div>
                         <label className={"font-medium flex flex-col gap-[5px]"}>Car Description
                             <textarea
                                 className={"details-text-area"}
                                 placeholder={"Paragraph one"}
                                 value={para1}
                                 onChange={(e) => setPara1(e.target.value)}
                             />
                             <textarea
                                 className={"details-text-area"}
                             placeholder={"Paragraph two"}
                             value={para2}
                             onChange={(e) => setPara2(e.target.value)}
                         />
                         <textarea
                             className={"details-text-area"}
                             placeholder={"Paragraph three"}
                             value={para3}
                             onChange={(e) => setPara3(e.target.value)}
                         />
                         <textarea
                             className={"details-text-area"}
                             placeholder={"Paragraph four"}
                             value={para4}
                             onChange={(e) => setPara4(e.target.value)}
                         />
                     </label>
                 </div>
                     <div className={"w-full flex justify-center"}>
                         <Button onClick={handleSubmit} className={"w-auto"}>Update car</Button>
                     </div>
                 </form>
                </div>
                    </div>
                    <div className={"mb-[50px]"}>
                        <div className={"bg-white w-full sm:w-[300px] p-6 rounded-md"}>
                            <div className={"font-semibold text-[1.3rem] pb-[25px]"}>Re-order images</div>
                            <div onClick={goToReOrder}>
                                <Button>Re-Order</Button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    )
}