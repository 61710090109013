import PortalNav from "../portal-nav";
import UpdateCars from "./UpdateCars";

export default function UpdatePage() {
    return (
        <div>
            <PortalNav />
            <UpdateCars />
        </div>
    )
}