import ImgLogo from '../../../images/logo.jpg';
import {Button} from "../../Button/ButtonElements";
import {signOut} from "firebase/auth";
import {auth} from "../../../firebase.config";
import {Link, useNavigate} from "react-router-dom";

function PortalNav() {
    const navigate = useNavigate();

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate("/portal");
        })
            .catch((error) => {
                console.log("Error whilst logging out")
            })
    }
    
    return (
        <div className={"flex justify-between items-center px-[10px] sm:px-[50px] h-[80px] bg-white w-[100vw] sticky top-0 border-b border-b-solid border-b-[#eee]"}>
            <Link to={"/"} className={"h-[100%] flex items-center justify-center"}>
              <img src={ImgLogo}  alt={""} className={"h-[80%]"}/>
            </Link>
            <div className={"flex gap-[10px] sm:gap-[25px] items-center"}>
                <Link to={"/portal"}>
                  <div className={"hover:text-[rgb(178,12,78)] text-[0.8rem] font-semibold sm:text-[1rem] transition duration-300"}>Add a Car</div>
                </Link>
                <Link to={"/portal/update"}>
                    <div className={"hover:text-[rgb(178,12,78)] text-[0.8rem] font-semibold sm:text-[1rem] transition duration-300"}>Update a Car</div>
                </Link>
            </div>
            <Button className={"h-[50%] "} onClick={handleLogout}>Log out</Button>
        </div>
    )
}

export default PortalNav;