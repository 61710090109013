import {useEffect, useState} from "react";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../../../firebase.config";
import {useNavigate} from "react-router-dom";
import UpdateCarCard from "../Update/UpdateCarCard";
import OrderCarCard from "./OrderCarCard";
import '../portal.css';
import {Button} from "../../../Button/ButtonElements";

export default function OrderCars() {
    const [carData, setCarData] = useState([]);
    const carsCollection = collection(db, 'cars');
    const navigate = useNavigate();
    const [selectedOrderCars, setSelectedOrderCars] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        buildCarData().then((carWithImages) => {
            setCarData(carWithImages);
        })
    }, []);

    const fetchCarDetails = async () => {
        try {
            const carDocs = await getDocs(carsCollection);
            return carDocs.docs.map(car => ({
                id: car.id,
                ...car.data()
            }));
        } catch (err) {
            console.log("Error getting car document", err);
        }
    }
    const buildCarData = async () => {
        const carWithImages = [];
        await fetchCarDetails().then(async (results) => {
            for(const car of results) {
                const imagesRef = collection(db, `cars/${car.id}/images`);
                const imageDocs = await getDocs(imagesRef);
                const images = imageDocs.docs.map(doc => ({
                    docId: doc.id,
                    ...doc.data()
                }));

                const sortedImages = images.slice().sort((a, b) => a.id - b.id);
                carWithImages.push({ ...car, images: sortedImages });
            }
        });
        return carWithImages;
    }

    function addCarToArray(image) {
        if(selectedOrderCars.includes(image)) {
            setSelectedOrderCars((prev) => prev.filter((doc) => doc.id !== image.id));
        } else {
            setSelectedOrderCars((prev) => [...prev, image]);
        }
    }

    function addSelectedCarClass(image) {
        if (selectedOrderCars.includes(image)) {
            return 'selected-image';
        } else {
            return '';
        }
    }

    function findIndexOfSelectedOrder(image) {
        if (selectedOrderCars.includes(image)) {
            return selectedOrderCars.findIndex((doc) => doc.id === image.id) + 1;
        } else {
            return null;
        }
    }

    async function finaliseSelectedOrder() {
        console.log(selectedOrderCars);
        if (selectedOrderCars.length === carData.length)
        {
            try {
                for (const car of selectedOrderCars) {
                    const carIndex = selectedOrderCars.findIndex((doc) => doc.id === car.id);
                    const collectionRef = doc(db, `cars/${car.id}`);
                    await updateDoc(collectionRef, {
                        order: carIndex + 1
                    })
                        .then(() => {
                            navigate("/portal/update");
                        })
                        .catch(error => {
                            console.log(error.message)
                        })
                }
            } catch(err) {
                console.log(err);
            }
        } else {
            setError('Need all images have been selected');
        }
    }

    return (
        <div className={"w-full h-full flex flex-col items-center justify-center mb-[50px]"}>
            <div className={"text-[1.5rem] my-8"}>Order your Cars</div>
            <p className={"text-center text-gray-600 pb-[25px]"}>Select your cars in the correct order:</p>
            <div className={"text-red-600 pb-[15px]"}>{error}</div>
            <div onClick={finaliseSelectedOrder} className={"mb-[25px]"}>
                <Button>Confirm Selected Order</Button>
            </div>
            <div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-[15px] "}>
                {carData.length > 0 ? carData.map((car, index) => (
                    <div key={index} onClick={() => addCarToArray(car)}
                         className={`rounded-lg hover:cursor-pointer ${addSelectedCarClass(car)} border-[4px]`}>
                        <div
                            className={"z-50 absolute bg-[rgb(178,12,78)] text-white w-[40px] flex items-center justify-center h-[40px] font-semibold rounded-br-lg"}>{findIndexOfSelectedOrder(car)}</div>
                        <OrderCarCard car={car}/>
                    </div>
                )) : <div>Loading...</div>}
            </div>
            <div onClick={finaliseSelectedOrder} className={"mt-[25px]"}>
                <Button>Confirm Selected Order</Button>
            </div>
        </div>
    )
}