import {useEffect, useState} from "react";

function PortalHeader() {
    const [carRef, setCarRef] = useState('');
    const connectingString = "<<";
    useEffect(() => {
        const carRef = localStorage.getItem('docId');
        if (carRef) 
            setCarRef(carRef);
    }, []);
    return (
        <div>
            <div className={"w-[100vw] flex flex-col items-center justify-center py-[50px]"}>
                <div className={"font-medium text-[2rem] text-center"}>Welcome to the Motors <span
                    className={"text-[rgb(178,12,78)]"}>For</span> You Portal!
                </div>
                <p className={"text-center"}>(We hate the Leicester, we hate the Derby)</p>
            </div>
            <div className={"w-full flex justify-center gap-[5px] text-gray-600 font-medium pb-[30px]"}>
                <div style={{ borderBottom: !carRef ? '1px solid rgb(178,12,78)' : '' }}>Add a Car</div>
                <div>{connectingString}</div>
                <div style={{ borderBottom: carRef ? '1px solid rgb(178,12,78)' : '' }}>Finalize details</div>
            </div>
        </div>
    )
}

export default PortalHeader;