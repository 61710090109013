import {collection, doc, getDocs} from "firebase/firestore";
import {db} from "../../../../firebase.config";
import {useEffect, useState} from "react";
import sort from "../../../Stock/Sort/Sort";
import UpdateCarCard from "./UpdateCarCard";
import {Button} from "../../../Button/ButtonElements";
import {useNavigate} from "react-router-dom";

export default function UpdateCars() {
    const [carData, setCarData] = useState([]);
    const carsCollection = collection(db, 'cars');
    const navigate = useNavigate();
    
    const fetchCarDetails = async () => {
        try {
            const carDocs = await getDocs(carsCollection);
            return carDocs.docs.map(car => ({
                id: car.id,
                ...car.data()
            }));
        } catch (err) {
            console.log("Error getting car document", err);
        }
    }
    const buildCarData = async () => {
        const carWithImages = [];
        await fetchCarDetails().then(async (results) => {
            for(const car of results) {
                const imagesRef = collection(db, `cars/${car.id}/images`);
                    const imageDocs = await getDocs(imagesRef);
                    const images = imageDocs.docs.map(doc => ({
                        docId: doc.id,
                        ...doc.data()
                    }));

                    const sortedImages = images.slice().sort((a, b) => a.id - b.id);
                    carWithImages.push({ ...car, images: sortedImages });
            }
        });
        return carWithImages;
    }
    
    function takeMeToOrderCars() {
        navigate('/portal/order-cars');
    }

    useEffect(() => {
        buildCarData().then((carWithImages) => {
            setCarData(carWithImages);
        })
    }, []);
    return (
        <div className={"w-full h-full flex flex-col items-center justify-center pb-[50px]"}>
            <div className={"text-[1.5rem] my-8"}>Update your Cars</div>
            <div onClick={takeMeToOrderCars} className={"p-4 text-[rgb(178,12,78)] border border-[#eee] shadow-md hover:cursor-pointer hover:shadow-xl transition duration-300 mb-10"}>Order your Cars</div>
            <div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[15px] "}>
                {carData.length > 0 ? carData
                    .slice()
                    .sort((a, b) => a.order - b.order)
                    .map((car, index) => (
                    <UpdateCarCard car={car} key={index}/>
                )) : <div>Loading...</div>}
            </div>
        </div>
    )
}