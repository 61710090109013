import React, { useEffect, useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDot, RxDotFilled } from 'react-icons/rx';
import styled from 'styled-components';
import Img1 from '../../mit/mit1.jpg';
import Img2 from '../../mit/mit2.jpg';
import Img3 from '../../mit/mit3.jpg';
import Img4 from '../../mit/mit4.jpg';
import Img5 from '../../mit/mit5.jpg';
import Img6 from '../../mit/mit6.jpg';

import Img7 from '../../mit/mit7.jpg';
import Img8 from '../../mit/mit8.jpg';
import Img9 from '../../mit/mit9.jpg';
import Img10 from '../../mit/mit10.jpg';
import Img11 from '../../mit/mit11.jpg';
import Img12 from '../../mit/mit12.jpg';
import Img13 from '../../mit/mit13.jpg';
import Img14 from '../../mit/mit14.jpg';
import Img15 from '../../mit/mit15.jpg';
import Img16 from '../../mit/mit16.jpg';
import Img17 from '../../mit/mit17.jpg';
import Img18 from '../../mit/mit18.jpg';
import Img19 from '../../mit/mit19.jpg';
import Img20 from '../../mit/mit20.jpg';
import Img21 from '../../mit/mit21.jpg';
import Img22 from '../../mit/mit22.jpg';

import Img24 from '../../mit/mit24.jpg';
import Img25 from '../../mit/mit25.jpg';
import Img26 from '../../mit/mit26.jpg';
import Img27 from '../../mit/mit27.jpg';
import Img28 from '../../mit/mit28.jpg';
import Img29 from '../../mit/mit29.jpg';
import Img30 from '../../mit/mit30.jpg';
import Img31 from '../../mit/mit31.jpg';
import Img32 from '../../mit/mit32.jpg';
import Img33 from '../../mit/mit33.jpg';
import Img34 from '../../mit/mit34.jpg';

import { Button } from '../Button/ButtonElements';
import Contact from '../Contact/Contact';
import Dropdown from '../Navbar/Dropdown';
import MenuList from '../Navbar/Menu';
import Navbar from '../Navbar/Navbar';
import './Slider.css';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';

const Background = styled.div`
  width: 100%;
  //padding-top: 50px;
  @media screen and (max-width: 500px) {
    //padding-top: 50px;
  }
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 600px) {
    margin-top: 115px;
  }
`;
const Container = styled.div`
  margin-top: 50px;
`;
const DetailsText = styled.div`
  padding: 10%;
  // padding-left: 40%;
  // padding-right: 40%;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 1rem;
  font-family: 'Poppins';
  font-weight: 300;
`;
const DetailsPrice = styled.h1`
  text-align: center;
  font-family: 'Poppins';
  font-weight: 300;
`;
const DetailsMiles = styled.h2`
  text-align: center;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 1.2rem;
`;
const DetailsHeader = styled.h1`
  font-size: 2.5rem;
  font-family: 'Poppins';
  font-weight: 400;
`;
const DetailsContainer = styled.div`
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    margin-top: -75px;
  }
  @media screen and (max-width: 550px) {
    margin-left: 15px;
  }
`;
const DetailsWrapper = styled.div``;
const Socials = styled.div``;
const ValueCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-right: 15px;
  transition: all 0.2s ease-in-out;
`;
const ValueCard2 = styled.div`
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //margin-left: -5px;
  margin-right: 15px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  margin-top: 0px;
  @media screen and (max-width: 500px) {
    margin-top: 50px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
`;
const SubTitle = styled.div`
  font-size: 1.2rem;
  text-align: center;
`;
const Number = styled.div`
  background: rgba(0, 0, 0, 0.2);
  text-decoration: none;
  outline: none;
  border: 1px solid rgb(178, 12, 78);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  padding: 20px;
  font-size: 1.2rem;

  &:hover {
    transition: all 0.2 ease-in-out;
    background: #fff;
    border: 1px solid rgb(178, 12, 78);
  }
`;
const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-right: 15px;
`;
const Social = styled.div`
  font-size: 1.8rem;
  text-align: center;
  margin-top: 50px;
`;
const Icons = styled.div`
  text-align: center;
`;
const Paragraph = styled.div`
  font-weight: bold;
  text-align: center;
  padding: 24px;
  margin-top: 25px;
`;
const SliderWrapper = styled.div`
  margin-top: 0%;
`;
const ContactHeader = styled.h1`
  margin-top: 30px;
  font-size: 1.6rem;
`;
const BtnWrap = styled.div`
  max-width: 300px;
  margin-top: 25px;
  margin-bottom: 50px;
`;
const Icon = styled.span`
  &:hover {
    transform: scale(1.05);
  }
`;
const BtnWrapWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const TextWrap = styled.div`
  padding-left: 75px;
  padding-right: 75px;
  @media screen and (max-width: 500px) {
    padding: 0;
  }
`;
const Mit = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [currentSlide, setCurrentSlide] = useState(0);

  const data = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,
    Img16,
    Img17,
    Img18,
    Img19,
    Img20,
    Img21,
    Img22,
    Img24,
    Img25,
    Img26,
    Img27,
    Img28,
    Img29,
    Img30,
    Img31,
    Img32,
    Img33,
    Img34,
  ];
  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? 33 : (prev) => prev - 1);
  };
  const nextSlide = () => {
    setCurrentSlide(currentSlide === 33 ? 0 : (prev) => prev + 1);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  console.log(currentIndex);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1200); // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Background>
      <Navbar toggle={toggle} />
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <MenuList />
      <Wrapper>
        <Container>
          <SliderWrapper>
            <div className="overflow-hidden flex justify-center align-middle contM">
              <div className=" w-[100vw] h-[450px] relative  boxM">
                <div
                  style={{
                    transform: `translateX(-${
                      currentSlide * (isSmallScreen ? 100 : 50)
                    }vw)`,
                  }}
                  className="w-[2300vw] h-full flex transition-transform duration-1000 img-boxM"
                >
                  <img
                    className="object-cover imgM"
                    src={data[0]}
                    alt=""
                    loading="priority"
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[1]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[2]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[3]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[4]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[5]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[6]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[7]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[8]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[9]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[10]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[11]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[12]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[13]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[14]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[15]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[16]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[17]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[18]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[19]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[20]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[21]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[22]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[23]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[24]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[25]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[26]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[27]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[28]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[29]}
                    alt=""
                  />
                  <img
                    className="w-[100vw] h-[250px] object-cover imgM"
                    src={data[30]}
                    alt=""
                  />
                </div>
                <div className="w-full h-full flex align-middle">
                  <div className="absolute w-fit left-[1%] mx-auto flex gap-8 top-[45%] arrowsM">
                    <div
                      onClick={prevSlide}
                      className="w-14 text-white h-14 border-[1px] border-white flex items-center justify-center hover:cursor-pointer hover:bg-black hover:text-white activ:bg-gray-900 duration-300 arrow-left absolute left-0"
                    >
                      <HiArrowLeft />
                    </div>
                  </div>
                  <div className="absolute w-fit right-[1%] mx-auto flex gap-8 top-[45%] arrowsM">
                    <div
                      onClick={nextSlide}
                      className="w-14 h-14 text-white border-[1px] border-white flex items-center justify-center hover:cursor-pointer  hover:bg-black hover:text-white activ:bg-gray-900 duration-300 arrow-right"
                    >
                      <HiArrowRight />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SliderWrapper>
        </Container>
        <DetailsContainer>
          <DetailsWrapper>
            <ValueCard2>
              <DetailsHeader>Mitsubishi Outlander</DetailsHeader>
              <SubTitle>
                2.0h 12kWh GX5h SUV 5dr Petrol Plug-in Hybrid CVT 4WD Euro 6
              </SubTitle>
            </ValueCard2>
            <ValueCard>
              <DetailsPrice>
                Only <strong>£9,990</strong>
              </DetailsPrice>
              <DetailsMiles>123,990 miles</DetailsMiles>
            </ValueCard>
            <ContactWrapper>
              <Number>
                <i class="fa-solid fa-phone"></i>
                <a href="tel: 0115 9313 131" className="num">
                  {' '}
                  0115 9313 131
                </a>
              </Number>
              <Number>
                <i class="fa-solid fa-phone"></i>
                <a href="tel: 07947 916 916" className="num">
                  {' '}
                  07947 916 916
                </a>
              </Number>
            </ContactWrapper>
            <Social>View car on our socials:</Social>
            <Icons>
              <Icon>
                <a
                  href="https://www.facebook.com/MotorsForYouLtd/"
                  class="fa fa-facebook"
                ></a>
              </Icon>
              <Icon>
                <a
                  href="https://www.instagram.com/ltdmotorsforyou/"
                  class="fa fa-instagram"
                ></a>
              </Icon>
            </Icons>
          </DetailsWrapper>
          <Socials></Socials>
        </DetailsContainer>
      </Wrapper>
      <Paragraph>Details you'll want to know:</Paragraph>
      <TextWrap>
        <DetailsText>
          We are pleased to offer to market this Outlander Hybrid. Just 2 former
          keepers and benefitting from full Mitsubishi service history with ten
          stamps in the book ! MOT until December 2024 with NO advisories,
          recent new rear discs and pads and has Michelin tyres all round.
        </DetailsText>
        <DetailsText>
          GX 5H offers a great specification including 18in Alloy Wheels, 360
          Degree Camera, 6-Channel Amplifier, Active Stability and Traction
          Control, Aerial - Shark Fin, Air Conditioning - Climate Control -
          Dual, Airbags - Front Passenger Deactivation Switch, Alarm, Armrest -
          Rear Centre, Bluetooth Audio Streaming, Bluetooth Hands Free Telephone
          Connection, Bonnet Badge, Bumpers - Colour Keyed - Front and Rear,
          Central Door Locking - Keyless Entry, Charge Point LED Light, Charging
          Cable - 5m 240v 10amp, Chrome Heated Switch Surround, City Crash
          Provision, Coat Hanger, Cruise Control, Cup Holder Lining - Leathered
          Covered to Match Interior, Curve Guidance Warning System, DAB Digital
          Radio, Door Panels - Leather Covered to Match Interior, Eco Mode
          Switch, Electric - Heated - Folding Mirrors with Indicators, Electric
          Heater, Electric Rear Tailgate, Electric Sunroof - Glass Tilt and
          Slide and Anti-trapping, Engine Start-Stop Button, Footwell Mood
          Lighting - Front and Rear - Blue LED, Front Fog Lights - LED, Front
          Seat Heaters, Gear Knob - Joystick Type, Glovebox - Illuminated,
          Handbrake - Leather In Chosen Upholstery Colour, Head Restraints -
          Height Adjustable - Front Row, Head Restraints - Height Adjustable 2nd
          Row, Headlamp Washers, Headlights - Automatic Light Sensor, Headlights
          - High Beam LED, Headlights - Levelling Adjustment, Interior Light
          with Map Reading Lights, Interior Lights - Front - LED, Interior
          Lights - Rear - LED, Keyless Operation System, Luggage Area Light,
          Multi Information Display - Colour, Number Plate Light - LED, Pan
          European Mapping, Pollen Filter, Premium Alpine Audio System, Privacy
          Glass - Rear Side and Rear Windows, RDS - Radio Data System
          Information, RISE - Reinforced Impact Safety Evolution - Body Safety
          System, Range Remaining Display, Rapid Charging Connection - CHAdeMO,
          Rear View Mirror - Auto Dimming, Regenerative Braking Paddles, Remote
          Charge-Heating Control via Smartphone App, Roof Rails - Silver, SD
          Memory Card Compatible Audio, Satellite Navigation - SD HD Navigation,
          Seatbelt Fastening Warning Light, Seatbelt Height Adjustment - Front,
          Seatbelts - 3-Point x2 - Front Row, Seatbelts - 3-Point x3 - Second
          Row, Seats - 60-40 Split - Second Row, Seats - Drivers 8 Way Power,
          Seats - Front Slide and Recline Adjustment with Fully Flat Function,
          Seats - Heated Rear, Speakers x6, Spoiler - Rear Colour Keyed, Spoiler
          Extended Roofline, Sunvisors - Front Illuminated with Vanity Mirrors,
          TMC - Traffic Message Channel Information, TPMS - Tyre Pressure
          Monitoring System, Tailgate Handle - Inner, Twin Rear USB Charging
          Ports, USB Connection, Wipers - Intermittent with Washer - Rear,
          Wipers - Variable Intermittent with Washer - Front, iPod Compatibility
          etc.
        </DetailsText>
        <DetailsText>
          H.P.I clear with full report, full owners book pack and spare key ! We
          are open 7 days a week from 8a.m until 8p.m. Part exchange and finance
          facilities available. Check out our reviews on Trustpilot, Google and
          Facebook. Please call to arrange a viewing and test drive by
          appointment. We look forward to hearing from you !
        </DetailsText>
      </TextWrap>
      <ContactHeader>Contact us regarding this listing:</ContactHeader>
      <BtnWrapWrap>
        <BtnWrap>
          <Button to="/contact">Contact Us</Button>
        </BtnWrap>
      </BtnWrapWrap>
    </Background>
  );
};

export default Mit;
