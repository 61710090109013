import {Button} from "../../../Button/ButtonElements";
import {Link, useNavigate} from "react-router-dom";

export default function UpdateCarCard(car) {
    const navigate = useNavigate();
    
    return (
        <div className={"border border-[#eee] shadow-md rounded-[10px] w-[350px]"}>
            <div className={"bg-[rgb(0,0,0,0.8)] text-white absolute font-semibold px-4 py-2 mt-[25px]"}>{car.car.label}</div>
            <img src={car.car.images[0].url} alt={""} className={"w-[100%] h-[200px] object-cover rounded-t-[10px]"}/>
            <div className={"px-6 py-2"}>
                <div className={"text-green-700 text-[1.3rem]"}>£{car.car.price.toLocaleString()}</div>
                <div className={"text-[1.4rem]"}>{car.car.title}</div>
                <p className={"text-[0.8rem] text-[#333] pt-1"}>{car.car.spec}</p>
                <div className={"py-1 font-medium text-[#333] pt-2"}><i className="fa-solid fa-car"></i> {car.car.miles.toLocaleString()} Miles</div>
                <Button to={`/portal/update-car/${car.car.id}`} className={"w-[100px] py-2 mt-4"}>Update</Button>
            </div>
        </div>
    )
}