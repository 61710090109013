import {collection, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../../firebase.config";
import {useEffect, useState} from "react";
import './portal.css';
import {
    closestCorners,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {Column} from "./components/Column";
import {arrayMove, sortableKeyboardCoordinates} from "@dnd-kit/sortable";
import {ImagesColumn} from "./components/ImagesColumn";
import {Column2} from "./components/Column(copy)";
import {Button} from "../../Button/ButtonElements";
import {useNavigate} from "react-router-dom";

function FinaliseDetails(props) {
    const [carDetails, setCarDetails] = useState({});
    const [imagesData, setImagesData] = useState([]);
    const [selectedOrderImages, setSelectedOrderImages] = useState([]);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const {carRef} = props;
    const imagesRef = collection(db, `cars/${carRef}/images`);
    const fetchCarDetails = async () => {
        try {
            const carDocRef = doc(db, "cars", carRef);
            const carDoc = await getDoc(carDocRef);
            if (carDoc.exists()) {
                const carData = carDoc.data();
                setCarDetails(carData);
            } else {
                console.log("No such document!");
            }
        } catch (err) {
            console.log("Error getting car document", err);
        }
    }
    
    const fetchImages = async () => {
        try {
            const imageDocs = await getDocs(imagesRef);
            const imageDataArray = [];
            
            imageDocs.forEach((doc) => {
                const imageData = {
                    docId: doc.id,
                    ...doc.data()
                };
                imageDataArray.push(imageData);
            })
            
            const sortedImageDataArray = imageDataArray.slice().sort((a, b) => a.id - b.id);
            setImagesData(sortedImageDataArray);
        } catch (err) {
            console.log("Error getting images:", err)
        }
    }
    const showImageData = () => {
        console.log(imagesData)
    }
    
    const getImagePos = id => imagesData.findIndex(image => 
        image.id === id
    )

    const handleDragEnd = event => {
        const {active, over} = event;

        if(active.id === over.id) return;

        setImagesData(images => {
            const originalPos = getImagePos(active.id)
            const newPos = getImagePos(over.id)

            return arrayMove(images, originalPos, newPos)
        });
    };

    const sensors = useSensors(
        useSensor(PointerSensor), 
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const reOrderImages = async () => {
        try {
            await imagesData.forEach((image, index) => {
                image.id = index + 1
            });
            for (const image of imagesData) {
                const collectionRef = doc(db, `cars/${carRef}/images/${image.docId}`);
                await updateDoc(collectionRef, {
                    id: image.id,
                    url: image.url
                })
                    .then(() => {
                        localStorage.removeItem('docId');
                        navigate("/portal/update");
                    })
                    .catch(error => {
                    console.log(error.message)
                })
            }
        } catch (err) {
            console.log('Error updating order:', err);
        }
    }
    
    useEffect(() => {
        fetchCarDetails().then();
        fetchImages().then();
    }, []);
    
    
    function addImageToArray(image) {
        if(selectedOrderImages.includes(image)) {
            setSelectedOrderImages((prev) => prev.filter((doc) => doc.id !== image.id));
        } else {
            setSelectedOrderImages((prev) => [...prev, image]);
        }
    }
    
    function addSelectedImageClass(image) {
        if (selectedOrderImages.includes(image)) {
            return 'selected-image';
        } else {
            return '';
        }
    }
    
    function findIndexOfSelectedOrder(image) {
        if (selectedOrderImages.includes(image)) {
            return selectedOrderImages.findIndex((doc) => doc.id === image.id) + 1;
        } else {
            return null;
        }
    }
    
    async function finaliseSelectedOrder() {
        if (selectedOrderImages.length === imagesData.length)
        {
            try {
                for (const image of selectedOrderImages) {
                    const imageIndex = selectedOrderImages.findIndex((doc) => doc.docId === image.docId);
                    const collectionRef = doc(db, `cars/${carRef}/images/${image.docId}`);
                    await updateDoc(collectionRef, {
                        id: imageIndex + 1,
                        url: image.url
                    })
                        .then(() => {
                            localStorage.removeItem('docId');
                            navigate("/portal/update");
                        })
                        .catch(error => {
                            console.log(error.message)
                        })
                }
            } catch(err) {
                console.log(err);
            }
        } else {
            setError('Need all images have been selected');
        }
    }
    
    function removeDocId() {
        localStorage.removeItem('docId');
        navigate("/portal");
    }
    
    return (
        <div className={"w-full h-full flex flex-col items-center justify-center mb-[50px]"}>
            <div className={"text-[1.2rem] font-medium py-2 pb-4"}>Order car photos</div>
            <div onClick={removeDocId} className={"p-4 text-[rgb(178,12,78)] border border-[#eee] shadow-md hover:cursor-pointer hover:shadow-xl transition duration-300 mb-10"}>Or add a new car</div>
            <p className={"pb-[15px]"}>Your selected order:</p>
            <div className={"grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6 border border-[#eee] bg-gray-100 gap-[5px] rounded-lg p-6"}>
                {selectedOrderImages.length !== 0 && selectedOrderImages.map((image, index) => (
                    <div key={index} onClick={() => addImageToArray(image)} className={""}>
                        <div className={"absolute bg-[rgb(178,12,78)] text-white w-[40px] flex items-center justify-center h-[40px] font-semibold rounded-br-lg"}>{findIndexOfSelectedOrder(image)}</div>
                        <img src={image.url} alt={""} className={"w-[150px] h-[100px] object-cover"}/>
                    </div>
                ))}
            </div>
            
            <p className={"pt-[50px] pb-[15px]"}>Select the order of your photos:</p>
            <div className={"grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 pb-[25px]"}>
                {imagesData.slice().sort((a,b) => a.id - b.id).map((image, index) => (
                    <div key={index} onClick={() => addImageToArray(image)} className={`rounded-lg hover:cursor-pointer ${addSelectedImageClass(image)} border-[4px]`}>
                        <div className={"absolute bg-[rgb(178,12,78)] text-white pr-4 pb-4 font-semibold pt-2 pl-2 rounded-br-lg"}>{findIndexOfSelectedOrder(image)}</div>
                        <img src={image.url} alt={""} className={"w-[200px] h-[150px] object-cover"}/>
                    </div>
                ))}
            </div>
            <div className={"text-red-600 pb-[15px]"}>{error}</div>
            <Button onClick={finaliseSelectedOrder}>Finalize Order</Button>
        </div>
    )
}

export default FinaliseDetails;