import './portal.css';
import PortalNav from "./portal-nav";
import AddACar from "./add-a-car";
import PortalHeader from "./portal-header";

function PortalPage() {
    const connectingString = "<<";
    return (
        <>
            <PortalNav />
            <PortalHeader />
            <AddACar />
        </>
    )
}

export default PortalPage;